import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BUTTON_TYPE,
  DEFAULTS,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants/BIConfigConstants';
import {
  TableHeading,
  BIToolSelectionFormButton,
  TableHeader,
  SelectorPaper,
  TableContainer,
  TableFooter,
  TableRow,
  RowContainer,
  DefaultMenuItem,
  BackdropContainer,
  CustomBackdrop,
  BIToolSelectionFormButtonContainer,
} from '../../utils/styles';
import Dropdown from '../../components/DropDown';
import { Backdrop, MenuItem } from '@mui/material';
import Text from '../../components/Text';
import Confirmer from '../../components/Confirmer';
import { useMutation } from 'react-query';
import {
  deleteConfiguredTool,
  updateConfiguredTool,
} from '../../utils/apiHelpers';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useToastContext } from '../../context/toastContext';
import {
  getBIToolToastMessage,
  getToolNameFromToolId,
} from '../../utils/helpers';

function BIToolSelector({
  biTools,
  plantId,
  userEmailId,
  configuredTool,
  refetchGetConfiguredTool,
}) {
  const defaultToolId = configuredTool?.isConfigured
    ? configuredTool?.biToolId
    : 0;
  const [selectedToolId, setSelectedToolId] = useState(defaultToolId);
  const [showResetConnectionsModal, setShowResetConnectionsModal] =
    useState(false);
  const [showReplaceConnectionsModal, setShowReplaceConnectionsModal] =
    useState(false);

  useEffect(() => {
    setSelectedToolId(defaultToolId);
  }, [defaultToolId]);

  const { toastDispatch } = useToastContext();

  const {
    mutate: updateConfiguredToolMutate,
    isLoading: isUpdateConfiguredToolLoading,
  } = useMutation(
    [QUERY_CONSTANTS.UPDATE_CONFIGURED_TOOL],
    async (reqBody) => {
      return updateConfiguredTool(reqBody);
    },
    {
      onSuccess: () => {
        refetchGetConfiguredTool();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: getBIToolToastMessage(
              false,
              getToolNameFromToolId(selectedToolId, biTools)
            ),
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
      },
      enabled: selectedToolId && plantId && userEmailId,
    }
  );

  const handleOnUpdateConfiguredTool = () => {
    const reqBody = {
      biToolId: selectedToolId,
      plantId: plantId,
      user: userEmailId,
    };
    updateConfiguredToolMutate(reqBody);
  };

  const {
    mutate: deleteConfiguredToolMutate,
    isLoading: isDeleteConfiguredToolLoading,
  } = useMutation(
    [QUERY_CONSTANTS.DELETE_CONFIGURED_TOOL],
    async (plantId) => {
      return deleteConfiguredTool(plantId);
    },
    {
      onSuccess: () => {
        refetchGetConfiguredTool();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: getBIToolToastMessage(true, configuredTool.connectionName),
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
      },
      enabled: Boolean(plantId),
    }
  );

  const handleOnResetConfiguredTool = () => {
    setShowResetConnectionsModal(false);
    deleteConfiguredToolMutate(plantId);
  };

  const handleOnReplaceConfiguredTool = () => {
    setShowReplaceConnectionsModal(false);
    handleOnUpdateConfiguredTool();
  };

  const handleOnSave = () => {
    configuredTool?.isConfigured
      ? setShowReplaceConnectionsModal(true)
      : handleOnUpdateConfiguredTool();
  };

  const showBackdropLoader =
    isUpdateConfiguredToolLoading || isDeleteConfiguredToolLoading;

  return (
    <SelectorPaper>
      <TableContainer data-testid={TEST_IDS.BIC_TOOL_SELECTOR}>
        <TableHeader>
          <TableHeading>
            {MESSAGE_STRINGS.BI_TOOL_SELECTION_FORM_HEADING}
          </TableHeading>
        </TableHeader>
        <TableRow>
          <RowContainer>
            <Dropdown
              sx={{ width: '17rem' }}
              data-testid={TEST_IDS.BIC_TOOL_SELECTOR_DROPDOWN}
              value={selectedToolId || DEFAULTS.SELECT}
              placeholderValue={DEFAULTS.SELECT}
              onChange={(event) => {
                setSelectedToolId(event.target.value);
              }}
            >
              <DefaultMenuItem value={DEFAULTS.SELECT} disabled>
                <Text isBody text={DEFAULTS.SELECT} />
              </DefaultMenuItem>
              {biTools.map((tool) => (
                <MenuItem value={tool.id} key={tool.id}>
                  <Text isBody text={tool.name} />
                </MenuItem>
              ))}
            </Dropdown>
          </RowContainer>
        </TableRow>
        {Boolean(selectedToolId) && (
          <TableFooter>
            <BIToolSelectionFormButtonContainer>
              <BIToolSelectionFormButton
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => {
                  setShowResetConnectionsModal(true);
                }}
                disabled={!configuredTool?.isConfigured}
                data-testid={TEST_IDS.BIC_TOOL_RESET}
              >
                {MESSAGE_STRINGS.BI_TOOL_SELECTION_FORM_RESET_BUTTON_LABEL}
              </BIToolSelectionFormButton>
            </BIToolSelectionFormButtonContainer>
            <BIToolSelectionFormButtonContainer>
              <BIToolSelectionFormButton
                onClick={handleOnSave}
                disabled={configuredTool?.biToolId === selectedToolId}
                data-testid={TEST_IDS.BIC_TOOL_SAVE}
              >
                {MESSAGE_STRINGS.BI_TOOL_SELECTION_FORM_SAVE_BUTTON_LABEL}
              </BIToolSelectionFormButton>
            </BIToolSelectionFormButtonContainer>
          </TableFooter>
        )}
      </TableContainer>
      {showResetConnectionsModal && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
          <Confirmer
            message={MESSAGE_STRINGS.RESET_CONNECTIONS_CONFIRMER_MESSAGE}
            YesHandler={handleOnResetConfiguredTool}
            NoHandler={() => {
              setShowResetConnectionsModal(false);
            }}
          />
        </Backdrop>
      )}
      {showReplaceConnectionsModal && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
          <Confirmer
            message={MESSAGE_STRINGS.RESET_CONNECTIONS_CONFIRMER_MESSAGE}
            YesHandler={handleOnReplaceConfiguredTool}
            NoHandler={() => {
              setShowReplaceConnectionsModal(false);
            }}
          />
        </Backdrop>
      )}
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </SelectorPaper>
  );
}

BIToolSelector.propTypes = {
  biTools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  plantId: PropTypes.number.isRequired,
  userEmailId: PropTypes.string.isRequired,
  configuredTool: PropTypes.shape({
    biToolId: PropTypes.number,
    connectionName: PropTypes.string,
    url: PropTypes.string,
    createdOn: PropTypes.number,
    createdBy: PropTypes.string,
    plantId: PropTypes.number,
    isConfigured: PropTypes.bool,
  }),
  refetchGetConfiguredTool: PropTypes.func,
};

export default BIToolSelector;
