import { biConfig } from '@smf/ui-util-app';

export async function getBiTools() {
  return biConfig.getBiTools();
}

export async function getConfiguredTool(plantId) {
  return biConfig.getConfiguredTool(plantId);
}

export async function updateConfiguredTool(body) {
  return biConfig.updateConfiguredTool(body);
}

export async function deleteConfiguredTool(plantId) {
  return biConfig.deleteConfiguredTool(plantId);
}
