import {
  Box,
  styled,
  Typography,
  Divider,
  Paper,
  MenuItem,
  Backdrop,
} from '@mui/material';
import SMFButton from '../components/SMFButton';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};

export const BICContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.configLighterDark,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '1rem',
  overflowY: 'auto',
}));

export const BICSubContainer = styled(Box)(() => ({
  marginBottom: '1.5rem',
}));

export const BICCaptionContainer = styled(Box)(() => ({
  marginTop: '1rem',
}));

export const BICHeading = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '1.5rem',
  lineHeight: '2.043rem',
  color: theme.palette.text.primary,
}));

export const BICCaption = styled(Typography)(() => ({
  ...typographyStyles,
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: '1rem',
}));

export const TableHeading = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '0.875rem',
  lineHeight: '1.251rem',
  color: theme.palette.text.primary,
}));

export const HeadingDivider = styled(Divider)(({ theme }) => ({
  border: `.0316rem solid ${theme.customColors.dividerGrey}`,
  margin: '.5rem 0',
}));

export const TableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.background.darkishBlackBlue,
  borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
  padding: '1.25rem 1rem',
}));

export const TableRow = styled(Box)(({ theme }) => ({
  borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
  padding: '.5rem 1rem',
  display: 'flex',
}));

export const SelectorPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  background: theme.palette.background.blackGrey,
  boxShadow: 'none',
  border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  color: theme.palette.text.primary,
  flexDirection: 'column',
}));

export const TableFooter = styled(Box)(({ theme }) => ({
  minHeight: '3.5rem',
  background: theme.palette.background.darkishBlackBlue,
  padding: '0 1rem',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const BIToolSelectionFormButtonContainer = styled(Box)(() => ({
  width: '7.5rem',
  marginLeft: '1.5rem',
}));

export const BIToolSelectionFormButton = styled(SMFButton)(() => ({
  width: '100%',
  fontWeight: 600,
  lineHeight: '1rem',
  fontSize: '.875rem',
  display: 'flex',
  height: '2.25rem',
  padding: '0.625rem 1rem',
  justify̧Content: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  flex: '1 0 0',
}));

export const FlexFullWidthContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
}));

export const RowContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  height: '3rem',
}));

export const ConnectionDetail = styled(Box)(() => ({
  width: '33.33%',
  display: 'flex',
  alignItems: 'center',
}));

export const FlexCenterContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ExternalLinkContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const ExternalLinkTypographyContainer = styled(Box)(() => ({
  marginRight: '0.563rem',
}));

export const ExternalLinkTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '0.875rem',
  lineHeight: '1.251rem',
  color: theme.palette.text.primary,
  textDecoration: 'underline',
}));

export const DefaultMenuItem = styled(MenuItem)(() => ({
  display: 'none',
}));

export const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const BackdropContainer = styled(Box)(() => ({
  display: 'flex',
  maxHeight: '100%',
  flex: 1,
  flexDirection: 'column',
}));
