import React from 'react';
import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';

const HeadingText = styled(Typography)(() => ({
  fontSize: '0.875rem',
  fontWeight: 700,
}));

const BodyText = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isDisabled' && prop !== 'isError' && prop !== 'isAlert',
})(({ theme, isDisabled, isError, isAlert }) => {
  let color;
  if (isDisabled) {
    color = theme.palette.background.darkGrey;
  } else if (isError) {
    color = theme.palette.text.errorText;
  } else if (isAlert) {
    color = theme.palette.text.lightYellowishGray;
  } else {
    color = 'inherit';
  }
  return {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: color,
  };
});

const Text = ({
  isHeading = false,
  isBody = false,
  isDisabled = false,
  isError = false,
  isAlert = false,
  text,
}) => {
  if (isHeading) return <HeadingText>{text}</HeadingText>;
  if (isBody)
    return (
      <BodyText isDisabled={isDisabled} isError={isError} isAlert={isAlert}>
        {text}
      </BodyText>
    );

  return <Typography>{text}</Typography>;
};

Text.propTypes = {
  isHeading: PropTypes.bool,
  isBody: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isAlert: PropTypes.bool,
  text: PropTypes.string || PropTypes.number,
};

export default Text;
