import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, Select, styled } from '@mui/material';
import DownArrow from '../../assets/img/downArrow.svg';

const CustomSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'isDefaultValue',
})(({ theme, isDefaultValue }) => ({
  color: !isDefaultValue
    ? theme.palette.text.primary
    : theme.palette.text.mediumLightCyanBlue,
}));

const CustomInputBase = styled(InputBase)(({ theme }) => ({
  display: 'flex',
  '&:focus': {
    backgroundColor: theme.palette.background.configLighterDark,
    border: `.0625em solid ${theme.palette.selected.greenSelect}`,
    borderRadius: 20,
  },
  '& .MuiInputBase-input': {
    border: `.0625em solid ${theme.palette.border.darkGrey}`,
    borderRadius: 20,
    backgroundColor: theme.palette.background.configLighterDark,
    paddingLeft: '1em',
    width: '100%',
    '&:focus': {
      borderRadius: 20,
    },
  },
  '& [aria-expanded=true]': {
    border: `.0625em solid ${theme.palette.selected.greenSelect}`,
    color: theme.palette.selected.greenSelect,
    borderRadius: 20,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: theme.palette.background.shuttleGray,
    WebkitTextFillColor: theme.palette.background.shuttleGray,
    cursor: 'not-allowed',
  },
  '& .MuiSelect-iconOutlined': {
    marginRight: '0.5em',
    color: 'white',
    top: 'auto',
  },
  '& .MuiSelect-icon.Mui-disabled': {
    '& path': { fill: theme.palette.background.shuttleGray },
  },
}));

const Dropdown = ({
  value = '',
  onChange,
  children,
  disabled = false,
  placeholderValue = '',
  disabledGrey = false,
  menuHeight = '15.75em',
  ...overides
}) => {
  return (
    <CustomSelect
      isDefaultValue={value === placeholderValue}
      disabled={disabled}
      data-testid="select-dropdown"
      value={value}
      onChange={onChange}
      variant="outlined"
      IconComponent={(p) => <DownArrow height={8} width={11} {...p} />}
      defaultValue={value}
      input={<CustomInputBase disabled={disabledGrey} />}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: { maxHeight: menuHeight || '15.75rem' },
      }}
      {...overides}
    >
      {children}
    </CustomSelect>
  );
};

Dropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  placeholderValue: PropTypes.string,
  maxHeight: PropTypes.string,
  disabledGrey: PropTypes.bool,
  borderRadius: PropTypes.number,
  menuHeight: PropTypes.string,
};

export default Dropdown;
