import PropTypes from 'prop-types';
import ExternalLink from '../../components/ExternalLink';
import { MESSAGE_STRINGS, TEST_IDS } from '../../constants/BIConfigConstants';
import { getMMDDYYYYFromEpoch } from '../../utils/helpers';
import {
  TableHeading,
  TableHeader,
  SelectorPaper,
  TableContainer,
  TableFooter,
  TableRow,
  RowContainer,
  ConnectionDetail,
  FlexFullWidthContainer,
} from '../../utils/styles';

function ConnectionDetails({ connectionDetails, isToolConfigured }) {
  const connectionDetailsHeadings = [
    MESSAGE_STRINGS.TABLE_HEADING_CONNECTION_NAME,
    MESSAGE_STRINGS.TABLE_HEADING_CREATED_ON,
    MESSAGE_STRINGS.TABLE_HEADING_CREATED_BY,
  ];

  return (
    <SelectorPaper>
      <TableContainer data-testid={TEST_IDS.CONNECTION_DETAILS}>
        <TableHeader>
          <FlexFullWidthContainer>
            {connectionDetailsHeadings.map((connectionDetailHeading) => (
              <ConnectionDetail key={`${connectionDetailHeading}-key`}>
                <TableHeading>{connectionDetailHeading}</TableHeading>
              </ConnectionDetail>
            ))}
          </FlexFullWidthContainer>
        </TableHeader>
        <TableRow>
          <RowContainer>
            {isToolConfigured && (
              <FlexFullWidthContainer>
                <ConnectionDetail>
                  <ExternalLink
                    label={connectionDetails.connectionName}
                    url={connectionDetails.url}
                  />
                </ConnectionDetail>
                <ConnectionDetail>
                  <TableHeading>
                    {getMMDDYYYYFromEpoch(connectionDetails.createdOn)}
                  </TableHeading>
                </ConnectionDetail>
                <ConnectionDetail>
                  <TableHeading>{connectionDetails.createdBy}</TableHeading>
                </ConnectionDetail>
              </FlexFullWidthContainer>
            )}
          </RowContainer>
        </TableRow>
        <TableFooter></TableFooter>
      </TableContainer>
    </SelectorPaper>
  );
}

ConnectionDetails.propTypes = {
  connectionDetails: PropTypes.shape({
    biToolId: PropTypes.number,
    connectionName: PropTypes.string,
    url: PropTypes.string,
    createdOn: PropTypes.number,
    createdBy: PropTypes.string,
    plantId: PropTypes.number,
    isConfigured: PropTypes.bool,
  }),
  isToolConfigured: PropTypes.bool,
};

export default ConnectionDetails;
