import { useState, useEffect } from 'react';
import {
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TEST_IDS,
} from '../../constants/BIConfigConstants';
import {
  BackdropContainer,
  BICCaption,
  BICCaptionContainer,
  BICContainer,
  BICHeading,
  BICSubContainer,
  CustomBackdrop,
  HeadingDivider,
} from '../../utils/styles';
import BIToolSelector from '../BIToolSelector';
import ConnectionDetails from '../ConnectionDetails';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { getBiTools, getConfiguredTool } from '../../utils/apiHelpers';
import { useQuery } from 'react-query';
import LoadingIndicator from '../../components/LoadingIndicator';

const BIConfig = () => {
  const { rxjsState } = useRxjsState();
  const [plantId, setPlantId] = useState(rxjsState?.plantId);
  const [userEmailId, setUserEmailId] = useState(rxjsState?.userData?.email);

  useEffect(() => {
    if (rxjsState.plantId) {
      setPlantId(rxjsState.plantId);
    }
    if (rxjsState?.userData?.email) {
      setUserEmailId(rxjsState?.userData?.email);
    }
  }, [rxjsState?.plantId, rxjsState?.userData?.email]);

  const [biTools, setBiTools] = useState([]);
  const [configuredTool, setConfiguredTool] = useState();

  const { isLoading: isGetBiToolsLoading, isFetching: isGetBiToolsFetching } =
    useQuery(
      [QUERY_CONSTANTS.GET_BI_TOOLS],
      async () => {
        return getBiTools();
      },
      {
        onSuccess: (response) => {
          setBiTools(response.tools);
        },
        enabled: true,
        refetchOnWindowFocus: false,
        retry: false,
      }
    );

  const {
    isLoading: isGetConfiguredToolLoading,
    isFetching: isGetConfiguredToolFetching,
    refetch: refetchGetConfiguredTool,
  } = useQuery(
    [QUERY_CONSTANTS.GET_CONFIGURED_TOOL],
    async () => {
      return getConfiguredTool(plantId);
    },
    {
      onSuccess: (response) => {
        setConfiguredTool(response);
      },
      enabled: Boolean(plantId),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const showBackdropLoader =
    isGetBiToolsLoading ||
    isGetBiToolsFetching ||
    isGetConfiguredToolLoading ||
    isGetConfiguredToolFetching;

  return (
    <BICContainer data-testid={TEST_IDS.BIC_MAIN_CONTAINER}>
      <BICSubContainer>
        <BICHeading data-testid={TEST_IDS.BIC_MAIN_HEADING}>
          {MESSAGE_STRINGS.BIC_HEADING}
        </BICHeading>
        <HeadingDivider />
        <BICCaptionContainer>
          <BICCaption data-testid={TEST_IDS.BIC_CAPTION}>
            {MESSAGE_STRINGS.BIC_CAPTION}
          </BICCaption>
        </BICCaptionContainer>
      </BICSubContainer>
      <BICSubContainer>
        <BIToolSelector
          biTools={biTools}
          plantId={plantId}
          userEmailId={userEmailId}
          configuredTool={configuredTool}
          refetchGetConfiguredTool={refetchGetConfiguredTool}
        />
      </BICSubContainer>
      <BICSubContainer>
        <ConnectionDetails
          connectionDetails={configuredTool}
          isToolConfigured={configuredTool?.isConfigured}
        />
      </BICSubContainer>
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </BICContainer>
  );
};
export default BIConfig;
