import { ThemeProvider } from '@mui/material';
import { theme } from '@smf/ui-util-app';
import { QueryClientProvider, QueryClient } from 'react-query';
import App from './containers/Routes';
import './App.css';
import { ToastProvider } from './context/toastContext';

export default function Root() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
