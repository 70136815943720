export const TEST_IDS = {
  BIC_MAIN_CONTAINER: 'bic-main-container',
  BIC_MAIN_HEADING: 'bic-main-heading',
  BIC_CAPTION: 'bic-caption',
  BIC_TOOL_SELECTOR: 'bic-tool-selector',
  BIC_TOOL_SELECTOR_DROPDOWN: 'bic-tool-selector-dropdown',
  BIC_TOOL_RESET: 'bic-tool-reset',
  BIC_TOOL_SAVE: 'bic-tool-save',
  CONFIRMER_BOX: 'confirmer-box',
  CONFIRMER_NO_BUTTON: 'confirmer-no-button',
  CONFIRMER_YES_BUTTON: 'confirmer-yes-button',
  CONNECTION_DETAILS: 'connection-details',
  EXTERNAL_LINK: 'external-link',
  BACKDROP: 'backdrop',
};

export const MESSAGE_STRINGS = {
  BIC_HEADING: 'Business Intelligence Configuration',
  BIC_CAPTION:
    'Select the preferred third-party Business Intelligence (BI) tool to conduct data analyses on factory data.',

  BI_TOOL_SELECTION_FORM_HEADING: 'Business Intelligence Tool',
  BI_TOOL_SELECTION_FORM_RESET_BUTTON_LABEL: 'Reset',
  BI_TOOL_SELECTION_FORM_SAVE_BUTTON_LABEL: 'Save',

  TABLE_HEADING_CONNECTION_NAME: 'Connection name',
  TABLE_HEADING_CREATED_ON: 'Created on',
  TABLE_HEADING_CREATED_BY: 'Created by',

  RESET_CONNECTIONS_CONFIRMER_MESSAGE:
    'This action will remove the existing connection.',

  //TOAST MESSAGES
  TOAST_BI_TOOL_PREFIX: 'Your link to',
  TOAST_BI_TOOL_SAVED_SUFFIX: 'has been saved.',
  TOAST_BI_TOOL_REMOVED_SUFFIX: 'has been removed.',
  'Toast.message.SUCCESS': 'Your changes have been successfully saved!',
  'Toast.hierarchy.upload.SUCCESS': 'File has been successfully uploaded!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
};

export const DEFAULTS = {
  SELECT: 'Select',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const QUERY_CONSTANTS = {
  GET_BI_TOOLS: 'GET_BI_TOOLS',
  GET_CONFIGURED_TOOL: 'GET_CONFIGURED_TOOL',
  UPDATE_CONFIGURED_TOOL: 'UPDATE_CONFIGURED_TOOL',
  DELETE_CONFIGURED_TOOL: 'DELETE_CONFIGURED_TOOL',
};
