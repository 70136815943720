import React, { forwardRef } from 'react';
import { Button, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../constants/BIConfigConstants';

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'buttonSize',
})(({ theme, type, buttonSize }) => {
  const isPrimary = type === BUTTON_TYPE.PRIMARY;
  const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
  return {
    height: isStandard ? '2.25rem' : '1.5rem',
    margin: theme.spacing(0.5),
    color: isPrimary
      ? theme.palette.text.secondary
      : theme.palette.text.primary,
    border: isPrimary
      ? `0.0625rem solid ${theme.palette.background.saveGreen}`
      : `0.0625rem solid ${theme.palette.text.primary}`,
    boxSizing: 'border-box',
    borderRadius: '1.25rem',
    minWidth: isStandard ? '6.5rem' : '3rem',
    fontSize: isStandard ? '.875rem' : '.75rem',
    background: isPrimary ? theme.palette.background.saveGreen : 'inherit',
    '&:hover': {
      color: isPrimary
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
      background: isPrimary ? 'inherit' : theme.palette.text.primary,
    },
    '&:disabled': {
      color: theme.palette.background.twentySevenJungleGrey,
      background: theme.palette.background.darkGrey,
      border: `0.0625rem solid ${theme.palette.border.wrapperGrey}`,
    },
    textTransform: 'none',
  };
});

const SMFButton = forwardRef(
  (
    {
      type = BUTTON_TYPE.PRIMARY,
      variant,
      size,
      onClick,
      color,
      disabled,
      text = BUTTON_TYPE.PRIMARY,
      children,
      buttonSize = BUTTON_SIZE.STANDARD,
      dataTestId = 'smf-button',
      ...other
    },
    ref
  ) => {
    return (
      <CustomButton
        type={type}
        variant={variant || 'contained'}
        size={size || 'medium'}
        color={color || 'primary'}
        onClick={onClick}
        role="button"
        disabled={disabled || false}
        buttonSize={buttonSize}
        ref={ref}
        data-testid={dataTestId}
        {...other}
      >
        {children || <Typography variant="subtitle1">{text}</Typography>}
      </CustomButton>
    );
  }
);

SMFButton.displayName = 'SMFButton';

SMFButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonSize: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default SMFButton;
