import { Box, Typography, styled, Button } from '@mui/material';
import PropTypes from 'prop-types';
import ExclamationIcon from '../../assets/img/blueExclamation.svg';
import { TEST_IDS } from '../../constants/BIConfigConstants';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const ConfirmerButton = styled(Button, {
  shouldForwardProp: (p) => p !== 'isApply',
})(({ theme, isApply }) => ({
  width: '7.25rem',
  height: '2.25rem',
  fontWeight: '600',
  border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  borderRadius: '1.25rem',
  color: theme.customColors[isApply ? 'black' : 'white'],
  backgroundColor: isApply ? theme.customColors.saveGreen : 'transparent',
  textTransform: 'none',
  '&.MuiButton-root:hover': {
    color: theme.customColors[isApply ? 'white' : 'black'],
    backgroundColor: isApply ? 'transparent' : theme.customColors.white,
    border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  },
}));

const AlertText = styled(Typography, {
  shouldForwardProp: (p) => p != 'isBold',
})(({ isBold }) => ({
  ...(isBold && { fontWeight: 700 }),
  fontSize: '.875rem',
  lineHeight: '1.19rem',
}));

const Confirmer = ({ message, YesHandler, NoHandler, children = null }) => {
  return (
    <Box
      sx={{
        width: '30rem',
        backgroundColor: (theme) => theme.customColors.blackGrey,
        borderRadius: '.5rem',
      }}
      data-testid={TEST_IDS.CONFIRMER_BOX}
    >
      <FlexBox
        sx={{
          padding: '1rem 0 1rem 1.5rem',
          height: '4rem',
          borderBottom: (theme) =>
            `1px solid ${theme.customColors.wrapperGrey}`,
        }}
      >
        <ExclamationIcon width={33} height={33} />
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.25rem',
            lineHeight: '1.7rem',
            marginLeft: '.75rem',
          }}
          onClick={NoHandler}
        >
          Confirm
        </Typography>
      </FlexBox>
      <Box
        sx={{ flexDirection: 'column', padding: '.94rem 2rem .94rem 4.25rem' }}
      >
        <AlertText sx={{ marginBottom: '1rem' }}>{message}</AlertText>
        <AlertText isBold>Would you like to continue?</AlertText>
      </Box>
      <FlexBox
        sx={{ justifyContent: 'flex-end', padding: '1rem 1.5rem 1.5rem 0' }}
      >
        <ConfirmerButton
          onClick={NoHandler}
          sx={{ marginRight: '1rem' }}
          data-testid={TEST_IDS.CONFIRMER_NO_BUTTON}
        >
          No
        </ConfirmerButton>
        {children ? (
          <>{children}</>
        ) : (
          <ConfirmerButton
            isApply
            onClick={(e) => YesHandler(e)}
            data-testid={TEST_IDS.CONFIRMER_YES_BUTTON}
          >
            Yes
          </ConfirmerButton>
        )}
      </FlexBox>
    </Box>
  );
};

Confirmer.propTypes = {
  message: PropTypes.string,
  YesHandler: PropTypes.func,
  NoHandler: PropTypes.func,
  children: PropTypes.node,
};

export default Confirmer;
