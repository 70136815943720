import { MESSAGE_STRINGS } from '../constants/BIConfigConstants';

export const getMMDDYYYYFromEpoch = (epoch) => {
  const date = new Date(epoch);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const getBIToolToastMessage = (isDelete, toolName) => {
  const suffix = isDelete
    ? MESSAGE_STRINGS.TOAST_BI_TOOL_REMOVED_SUFFIX
    : MESSAGE_STRINGS.TOAST_BI_TOOL_SAVED_SUFFIX;
  return `${MESSAGE_STRINGS.TOAST_BI_TOOL_PREFIX} ${toolName} ${suffix}`;
};

export const getToolNameFromToolId = (toolId, tools) => {
  return tools.find((tool) => tool?.id === toolId)?.name;
};
