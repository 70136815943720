import React from 'react';
import PropTypes from 'prop-types';
import ExternalLinkIcon from '../../assets/img/externalLinkIcon.svg';
import {
  ExternalLinkContainer,
  ExternalLinkTypography,
  ExternalLinkTypographyContainer,
  FlexCenterContainer,
} from '../../utils/styles';
import { TEST_IDS } from '../../constants/BIConfigConstants';

const ExternalLink = ({ label, url }) => {
  return (
    <FlexCenterContainer>
      <ExternalLinkContainer
        data-testid={TEST_IDS.EXTERNAL_LINK}
        onClick={() => {
          window.open(url, '_blank', 'noreferrer');
        }}
      >
        <ExternalLinkTypographyContainer>
          <ExternalLinkTypography>{label}</ExternalLinkTypography>
        </ExternalLinkTypographyContainer>
        <ExternalLinkIcon />
      </ExternalLinkContainer>
    </FlexCenterContainer>
  );
};

ExternalLink.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
};

export default ExternalLink;
